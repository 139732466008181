<template>
    <div id="reviews">
        <!-- Reviews -->
        <section id="reviews">
            <div class="section-content section-content-xs style-basic">
                <div class="container">
                    <!-- Title -->
                    <div class="row">
                        <div class="col-12 text-center text-md-left">
                            <p class="h1 styled-header font-weight-bold mb-4">Отзывы</p>
                        </div>
                    </div>
                    <!-- Cards -->
                    <div class="row">
                        <div v-for="review in reviews" :key="review.id" class="col-12 mb-4">
                            <div class="card card-horizontal card-max card-review">
                                <div class="card-body d-flex flex-column p-4">
                                    <div class="row d-flex">
                                        <img v-for="imgUrl in review.images" :key="imgUrl" class="openable" v-on:click="show" v-lazy="getImageSrc(imgUrl)" alt="Фото отзыва">
                                    </div>

                                    <div class="card-text mb-3">
                                        {{review.text}}
                                    </div>

                                    <p class="card-title ml-auto mt-auto mb-0">
                                        {{review.author}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="image-viewer" ref="zoomedImageViewer" @click.self="hide" style="display: none;">
            <button class="close" @click.prevent="hide"></button>
            <img ref="zoomedImage" src="" alt="Увеличенная фотография отзыва">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Reviews',
    methods: {
        getImageSrc: function(name) {
            return require(`../assets/img/reviews/${name}`)
        },
        show: function(event) {
            this.$refs.zoomedImageViewer.style.display = 'block'
            this.$refs.zoomedImage.setAttribute('src', event.target.getAttribute('src'))
        },
        hide: function() {
            this.$refs.zoomedImageViewer.style.display = 'none'
        }
    },
    data: function() {
        return {
            reviews: window.data.reviews
        }
    }
}
</script>